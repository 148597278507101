export default {
    path:"/accions",
    meta: {
        title:"Actions"
    },
    children:[
        {
            path     : "Existencias",
            name     : "Existencia",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/actions/LookExistence"),

            meta     : {
                title: "Existencia",
            },
        },
        {
            path     : "Precios",
            name     : "Precios",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/actions/SearchPrice"),

            meta     : {
                title: "Consulta de Precios",
            },
        },
    ]
}