import {useSsoStore} from "@/store/modules/sso.store";

export default {
    path: "/expediente",
    name: "Expediente",
    meta: {
        title: "Expediente",
    },
    children: [
        {
            path: "paciente",
            name: "ExpedientePaciente",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/expedient/ExpedientView.vue"),
            meta: {
                title: "Paciente"
            },
            children: [
                {
                    path: "consulta",
                    name: "consulta",
                    component: () => import("@/views/modules/medical/expedient/components/ConsultaComponent.vue")
                },
                {
                    path: "registroSoip",
                    name: "SOIPEXPEDIENTE",
                    component: () => import("@/views/modules/medical/expedient/components/SOIPComponent.vue")
                },
                {
                    path: "receta",
                    name: "RECETAEXPEDIENTE",
                    component: () => import("@/views/modules/medical/expedient/components/RecetaComponent.vue")
                },
                {
                    path: "esquema",
                    name: "EsquemaEXPEDIENTE",
                    component: () => import("@/views/modules/medical/expedient/components/EsquemaComponent.vue")
                },
                {
                    path: "analisis",
                    name: "AnalisisEXPEDIENTE",
                    component: () => import("@/views/modules/medical/expedient/components/AnalisisComponent.vue")
                },
                {
                    path: "pesos",
                    name: "PesoEXPEDIENTE",
                    component: () => import("@/views/modules/medical/expedient/components/PesoComponent")
                },
                {
                    path: "productos",
                    name: "ProductosEXPEDIENTE",
                    component: () => import("@/views/modules/medical/expedient/components/ProductoComponent.vue")
                },
                {
                    path: "servicios",
                    name: "ServiciosEXPEDIENTE",
                    component: () => import("@/views/modules/medical/expedient/components/ServicioComponent.vue")
                },
                {
                    path: "vencimientos",
                    name: "VencimientosEXPEDIENTE",
                    component: () => import("@/views/modules/medical/expedient/components/VencimientoComponent.vue")
                },
            ]
        },
    ]
}