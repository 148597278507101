import {useSsoStore} from "@/store/modules/sso.store";

export default {
    path     : '/admin',
    component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/components/AdminLayout"),
    meta     : {
        title: 'Administrador',
        async beforeResolve(routeTo, routeFrom, next) {
            const usuarioStore = useSsoStore()
            if (usuarioStore.esAdmin || usuarioStore.esMedic || usuarioStore.esMaster) {
                next();
            } else {
                next({name:'default'});
            }
        },
    },
    children:[
        {
            path: "esclavos",
            name: "Esclavos",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/Estadistica.vue"),

            meta     : {
                title: "Esclavos",
            },
        },
        {
            path: "ventas",
            name: "Ventas",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/Ventas"),

            meta     : {
                title: "Ventas",
            },
        },
        {
            path: "store",
            name: "Codigo",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/components/SaleProductoByCoder"),

            meta     : {
                title: "Ventas Por Codigo",
            },
        },
        {
            path: "product",
            name: "Product",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/reports/Producto"),

            meta     : {
                title: "Ventas producto",
            },
        },
        {
            path: "service",
            name: "Service",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/admin/reports/Service"),

            meta     : {
                title: "Ventas Servicio",
            },
        },
    ]

}
