export default {
    path:"/vencimiento",
    meta: {
        title:"Vencimiento"
    },
    children:[
        {
            path:"calendar",
            name:"Calendar",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/Vencimientos"),

            meta     : {
                title: "Calendar",
            },
        },
        {
            path:"agenda",
            name:"Agenda",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/components/Agenda"),

            meta     : {
                title: "Agenda",
            },
        }
    ]
}