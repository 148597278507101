import vencimientoRouting from "@/router/routes/modules/vencimiento.routing";
import expedienteRouting from "@/router/routes/modules/expediente.routing";
import hospitalRouting from "@/router/routes/modules/hospital.routing";
import accionesRouting from "@/router/routes/modules/acciones.routing";
import returnRouting from "@/router/routes/modules/return.routing";
import prescricionsRouting from "@/router/routes/modules/prescricions.routing";
import soipRouting from "@/router/routes/modules/soip.routing";
import consultaRouting from "@/router/routes/modules/consulta.routing";
import pacienteRouting from "@/router/routes/modules/paciente.routing";
import {useSsoStore} from "@/store/modules/sso.store";
import formconsultaRouting from "@/router/routes/modules/formconsulta.routing";
import catalagosRouting from "@/router/routes/modules/catalagos.routing";
import viewconsultaRouting from "@/router/routes/modules/viewconsulta.routing";
import ComprasRouting from "@/router/routes/modules/compras.routing";
import administradorRouting from "@/router/routes/modules/administrador.routing";
export default {
    path     : '/user',
    name     : 'user',
    component: () => import(/* webpackPrefetch: true */"@/layouts/DefaultLayout"),
    meta     : {
        title         : 'user',
        authRequired  : true,
        async beforeResolve(routeTo, routeFrom, next) {
            const usuarioStore = useSsoStore()
            if (usuarioStore.esAdmin || usuarioStore.esMedic || usuarioStore.esMaster) {
                next();
            } else {
                next({name:'default'});
            }
        },
    },
    children : [
        vencimientoRouting,
        expedienteRouting,
        hospitalRouting,
        accionesRouting,
        returnRouting,
        prescricionsRouting,
        consultaRouting,
        soipRouting,
        pacienteRouting,
        formconsultaRouting,
        catalagosRouting,
        viewconsultaRouting,
        ComprasRouting,
        administradorRouting
    ]
}
