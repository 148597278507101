import {useSsoStore} from "@/store/modules/sso.store";

export default {
    path: "/hospital",
    meta: {
        title: "Hospital",
    },
    children: [
        {
            path: "hospi",
            name: "ListadoHospital",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/hospital/Hospital"),

        },
        {
            path: "mascota",
            name: "Mascota",
            component: () => import("@/views/modules/reception/hospital/Inicio"),
            meta: {
                title: "Mascota",
            },
            children:[
                {
                    path: "card",
                    name: "Card",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/hospital/components/Inicio"),
                    meta: {
                        title: "Hospi",

                    },
                    children: [

                        {
                            path: "mask",
                            name:"Data",
                            component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/hospital/components/CardMascota.vue"),

                        },
                    ]

                },
            ]
        },


    ]
}