import {defineStore} from "pinia";
import {DatosPersonales} from "@/api/connection/providers/DatosPersonales.service";
import NotificacionError from "@/helpers/notifications/NotificacionError";

export const useUsuarioStore = defineStore('usuario', {
    state: () => ({

        nombre: null,
        primerApellido: null,
        segundoApellido: null,
        curp: null,
        rfc: null,
        telefono: null,
        nombreCompleto: null,
        calle: null,
        colonia: null,
        numExterior: null,
        numInterior: null,
        cruzamiento1: null,
        cruzamiento2: null,
        referencia: null,
        modulos: [],
        email: null,
        id: null,
        sucursal: null,
        sucursalcompleto:{},
        direccionSucursal: '',
        nombreSucursal: '',
        profile_foto: '',
        notificacion: 0,
        notificaciones: [],
        user:{}

    }),

    getters: {
        //**
        // cargarNotify
        // ***//
    },

    actions: {
        async cargarDatosDeUsuario() {
            try {
                const userInfo = await DatosPersonales.obtener()

                if (userInfo.status === 1) {
                    await this.llenarDatos(userInfo)
                } else if (userInfo.status === 0) {
                    await NotificacionError.mostrar(userInfo.msj);
                    return true;
                }
            } catch (e) {
                throw e
            }
        },

        async llenarDatos(userInfo) {

            this.nombreCompleto = userInfo.User.name;
            this.email = userInfo.User.email;
            this.id = userInfo.User.id;
            this.sucursal = userInfo.User.sucursal_id;
            this.nombreSucursal = userInfo.Sucursal.Nombre;
            this.direccionSucursal = userInfo.Sucursal.Direccion;
            this.sucursalcompleto = userInfo.Sucursal;
            this.profile_foto = userInfo.User.profile_photo_url;
            this.user = userInfo.User;

        },

        async limpiarDatos() {
            this.nombreCompleto = null;
            this.email = null;
            this.id = null;
            this.sucursal = null;
            this.nombreSucursal = null;
            this.direccionSucursal = null;
            this.modulos = [];
        }
    },
})