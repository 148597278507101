
export default {
    path: "/view",
    name: "View",
    meta: {
        title: "Consulta",
    },
    children: [
        {
            path: "view",
            name: "VerConsulta",

            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/views/View"),

        },
    ]
}