import {useSsoStore} from "@/store/modules/sso.store";

export default {
    path: "/formulario",
    name: "Formulario",
    meta: {
        title: "Formulario",
    },
    children: [
        {
            path: "prospecto",
            name: "Prospecto",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/form/Home.vue"),
            meta     :{
                title:  "Registar Consulta",
            },
            children: [
                {
                    path: 'search',
                    name: 'Search',
                    component:()=> import("@/views/modules/medical/form/BuscarPaciente.vue")
                },
                {
                    path: 'detallesoip',
                    name: 'Deta',
                    component:()=> import("@/views/modules/medical/form/SOIP")
                },
                {
                    path: 'receta',
                    name: 'Receta',
                    component:()=> import("@/views/modules/medical/form/Receta.vue")
                },
                {
                    path: 'file',
                    name: 'File',
                    component:()=> import("@/views/modules/medical/form/Esquema.vue")
                },
                {
                    path: 'analisis',
                    name: 'Analisis',
                    component:()=> import("@/views/modules/medical/form/Analisis.vue")
                },
                {
                    path: 'final',
                    name: 'Finalizado',
                    component:()=> import("@/views/modules/medical/form/Final")
                },
            ]
        },
    ]
}