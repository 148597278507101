import { defineStore } from "pinia";
import { UsuarioService } from "@/api/connection/providers/Usuarios.service";
import moment from "moment";
import { useUsuarioStore } from "@/store/modules/usuario.store";
import router from "@/router";
import NotificacionError from "@/helpers/notifications/NotificacionError";
import {NotificacionesModal} from "@/helpers/notifications/notificacionesModal";
import { DatosPersonales } from "@/api/connection/providers/DatosPersonales.service";

//
moment.locale('es')
export const useSsoStore = defineStore('sso', {
    state: () => ({
        token: null,
        vigencia: null,
        email: null,
        rol: null,
        sucursal: null,
        timer: null,
        contador: null,
        now: moment(),
        expiraEn: '',
        user: {},
        activo: null,
        autenticado: false,
        authenticated: !!localStorage.getItem("token_access"),
        token_acces: localStorage.getItem("token_access"),
        Serial:'MTllYzJkY2ZfXzIwMjMtMDMtMTNfXzIwMjMtMDQtMTIjIyNwZkxjTVNVZHk2WXB2VG45K3lrQUR4RmxhZkhocVo2MkxaN1o2NktLb0YwRUYwN2lhV0o2YnEvcjJpd0xObG9OZGRvQzM2bENUT1k2RzlDenVZY1J2dEVvTGg3aEdiK3NyUGgraCt4QUtMNFQzdDNMbHErVFFjSDg4TlVoRXVuYUhwc3NiemlUSVJSY3ZoZGRXSTlESDVWVDIvTVVzcCthK1Y5QXhQMm4wZTZrZEc5dk9pa1RsSFZrZ3hrYk1lOVFZbnRyUzVnWmt5a3E3bjZlZ21UaERrSWlYZkZMZ1ZwVVJ3NzBkcUZLS1U4ZUJpelRRNEZDWXlYZGoxTnFTakxEdGI4YWxJL2JZVWhDeXRsM2ZBcnRPNkFYSDc5QnYrdTBlT1AvdEZNVXk5cVN4cG1CcUdIUXJienZlY24rdFB1RUtzZWkweXI0QTlTZGxqS2xZaHVGT0FUSlFlZnJ4cnFXejM3YVBnb1l0cUF0NXNOQ2JGMTIzcjZHVlFLTGt0QTc1ZnA2ai9sTFVneS9icm1mT0tnMkJvRUlKdXhTWVNWdDZ2bTdkM0crakthU2wrL2czcldiN1AxdW1mOVlFNDVhUGFVbEdDM1dBRXdlV1AyajZKNlRLVXFHSDFhVHBFWWVWWHUwQ1JWWkJIQ3B2T1ZIOXlTZ2hoeVNaUXpNNEM2dURZcUN0VlB4V1VGK0ZYUUJvdEU2ZlVpT1FtL1hkZXExQ0hxSEUzK1RSUHcrNnJ6ZE8waDdtU25KNTRLdUhITlFwWVI4cjRsYTM2d080U3A2alo4c1ZhVmU0OW9WcXlhTk5OUGlENXA4WnhPazZDb0VsK3pzNG9EdW5POXh4RGN5Y3NMdEdPT1N1WmJ4YTVuTE1lOHVtWUFsRVV3ZkRjdDMvd2NHU2F6Q1ZJZz0='
    }),
    //
    getters: {
        sesionInactiva: (state) => (
            state.token ? state.activo : true
        ),
        getToken: (state) => state.token_acces,
        getAuthenticated: (state) => state.authenticated,
        esMaster: (state) => state.rol === 1,
        esCajero: (state) => state.rol === 2,
        esAdmin: (state) => state.rol === 3,
        esMedic: (state) => state.rol === 4,
    },
    mutations: {
        login(state) {
            state.authenticated = true;
            state.token = this.token;
        },
    },
    //
    actions: {
        async iniciarSesion(email, password) {
            try {
                const authToken = await UsuarioService.login(email, password)
                //----INICIO DE SESION
                this.token = authToken.access_token
                this.rol = authToken.rol
                this.sucursal = authToken.sucursal
                this.usuario = authToken.user;
                if (authToken.status === 1 && authToken.rol === 4 || authToken.rol === 3 || authToken.rol === 1) {
                    this.autenticado = true;
                    localStorage.setItem('token_access', JSON.stringify(authToken.access_token));
                    return true;
                }else if (authToken.status === 0){
                   await NotificacionError.mostrar(authToken.msj);
                    return false;
                }else {
                    await NotificacionError.mostrar("Acceso no autorizado");
                    return false;
                }
            } catch (e) {
                throw e
            }
        },
        async cerrarSesion() {
            try {
                let response = await DatosPersonales.finalizarSesion();
                if (response.status === 1) {
                    await NotificacionesModal.PantallaExito(response.msj)
                    localStorage.removeItem('token_access');
                    this.autenticado = false;
                    this.sucursal = null;
                    this.token = null
                    this.email = null
                    const usuarioStore = useUsuarioStore()
                    usuarioStore.nombreCompleto = null
                    usuarioStore.email = null
                    await router.push({ name: 'logout' })
                }else if (response.status === 0 && response.tipo === 1){
                    await NotificacionError.mostrar(response.msj)
                    await router.push('/cuentas/cuenta/corte');
                }else if (response.status === 0 && response.tipo === 2){
                    await NotificacionError.mostrar(response.msj)
                    await router.push('/return/insumo');
                }
                
            } catch (error) {
                localStorage.clear();
                sessionStorage.clear();
                await router.push({ name: 'logout' })
                await NotificacionError.mostrar(error);
            }

        },

    },
})