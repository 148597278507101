export default {
    path:"/paciente",
    meta: {
        title:"Paciente"
    },
    children:[
        {
            path     : "paci",
            name     : "Paciente",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/tables/Expediente"),

            meta     : {
                title: "Paciente",
            },
        },
        {
            path     : "consu",
            name     : "consu",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/tables/Consulta"),

            meta     : {
                title: "Consulta",
            },
        },
    ]
}