export default {
    path:"/return",
    meta: {
        title:"Devoluciones e Insumos"
    },
    children:[
        {
            path     : "devolucion",
            name     : "Devoluciones",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/actions/return/Devoluciones"),

            meta     : {
                title: "Devoluciones",
            },
        },
        {
            path     : "insumo",
            name     : "Insumos",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/actions/return/Insumos"),

            meta     : {
                title: "Insumos",
            },
        },


    ]
}