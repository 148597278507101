import {useSsoStore} from "@/store/modules/sso.store";

export default {
    path: "/prescricions",
    name: "Hospital",
    meta: {
        title: "Hospital",
    },
    children: [
        {
            path: "",
            name: "ListadoRecetas",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/tables/Receta"),

        },
    ]
}