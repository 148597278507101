export default {
    path: "/compras",
    name: "Compras",
    meta: {
        title: "Compras",
    },
    children:[
        {
            path: "detalle",
            name: "Compras",
            children: [
                {
                    path: "mascota",
                    name: "MASCOTA",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/components/HistorialMascota"),
                },
                {
                    path: "cliente",
                    name: "Cliente",
                    component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/components/HistorialCliente"),
                    meta: {
                        title: "Historial Cliente",

                    }
                },

            ]

        },
        {
            path: "comision",
            name: "Comision",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/reception/components/Comision.vue"),
        }
    ]
}