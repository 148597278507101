import {useSsoStore} from "@/store/modules/sso.store";

export default {
    path: "/soip",
    name: "SOIP",
    meta: {
        title: "SOIP",
    },
    children: [
        {
            path: "buscar",
            name: "ListadoSOIP",
            component: () => import(/* webpackPrefetch: true */"@/views/modules/medical/tables/SOIP"),

        },
    ]
}